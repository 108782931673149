import React from "react"
import Layout from "../../components/layout"
import SmoothScroll from "../../components/smooth-scroll"
import WorkContent from "../../components/work-content"
import { graphql } from "gatsby"

const Work = ({ data }) => {
  return (
    <Layout>
      <WorkContent imageData={data} />
    </Layout>
  )
}

export default Work

export const pageQuery = graphql`
  query {
    conductorThumbnail: file(
      relativePath: { eq: "conductor/conductor-hero.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    colorInspThumbnail: file(
      relativePath: { eq: "color-inspiration/color-insp-hero-2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomadThumbnail: file(relativePath: { eq: "nomad/nomad-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    dreamnestThumbnail: file(
      relativePath: { eq: "dreamnest/dreamnest-hero.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
