import { Link } from "gatsby"
import styled from "styled-components"
import { device } from "./device"
import Img from "gatsby-image"

export const WorkContentGrid = styled.div`
  display: grid;
  width: 320px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
  row-gap: 8rem;
  column-gap: 0.5rem;
  margin-bottom: 10rem;
  margin-top: 8rem;
  position: relative;

  @media ${device.tablet} {
    grid-template-columns: repeat(5, 1fr) 2rem 2rem repeat(5, 1fr);
    grid-template-rows: max-content;
    /* column-gap: 2rem; */
    row-gap: 14rem;
    width: 100%;
    margin-bottom: 14rem;
  }
`

export const PageTitle = styled.span`
  position: relative;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 2rem;

  h1 {
    color: ${props => props.theme.blackj};
    font-family: ${props => props.theme.titleFont};
    font-size: 3rem;
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
`

export const Idx = styled.div`
  font-family: ${props => props.theme.bodyFont};
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-align: left;
  color: gray;
  /* position: relative; */
  /* top: 2rem; */
`

export const ProjectPreview = styled.div`
  position: relative;
  grid-column-end: span 4;
  grid-row-end: span 2;

  @media ${device.tablet} {
    grid-column: 2 / span 4;

    &:nth-child(even) {
      grid-column: span 4 / 12;
    }
  }
`

export const Thumbnail = styled(Link)`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;

  /* height: 100%; */

  /* ${Img} {
    width: 100%;
    height: 100%;
  } */

  /* .gatsby-image-wrapper{
    width: 100%;
  }

  img {
    width: 100%;
  } */
  margin-bottom: 0.5rem;
`

export const AuthLink = styled.a`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0.5rem;
`

export const ProjectDetails = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
`

export const ProjectTitle = styled.span`
  text-transform: uppercase;
  font-weight: 400;
`
export const ProjectDate = styled.span``

export const FloatingImageWrapper = styled.div`
  overflow: hidden;
  /* content-box makes sure padding adds to declared height */
  /* Vertical padding is based on parent width */
  /* So we want 9/16, converted to % as our vertical padding */
  padding-bottom: calc(100% / ${props => props.aspectRatio});
`

export const FloatingImageInner = styled.div`
  position: absolute;
  height: calc(100% + (2 * ${props => props.overflow}));
  top: calc(-1 * (${props => props.overflow}));
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: 50% 0%;
  will-change: transform;
`
