import { Link } from "gatsby"
import React from "react"
import { Container, easeInOut } from "../styles/global-styles"
import FloatingImage from "./floating-image"
import Img from "gatsby-image"
import {
  WorkContentGrid,
  ProjectPreview,
  Idx,
  Thumbnail,
  ProjectDetails,
  ProjectTitle,
  ProjectDate,
  PageTitle,
  AuthLink,
} from "../styles/work-styles"

const WorkContent = ({ imageData }) => {
  const {
    conductorThumbnail,
    colorInspThumbnail,
    nomadThumbnail,
    dreamnestThumbnail,
  } = imageData
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={easeInOut(0.3)}
    >
      <PageTitle>
        <h1>Projects</h1>
      </PageTitle>

      <WorkContentGrid>
        <ProjectPreview>
          <Thumbnail to="/work/conductor/">
            <Img fluid={conductorThumbnail.childImageSharp.fluid} />
          </Thumbnail>
          <ProjectDetails>
            <ProjectTitle>Conductor</ProjectTitle>
            <ProjectDate>2020</ProjectDate>
          </ProjectDetails>
        </ProjectPreview>
        <ProjectPreview>
          <Thumbnail to="/work/nomad">
            <Img fluid={nomadThumbnail.childImageSharp.fluid} />
          </Thumbnail>
          <ProjectDetails>
            <ProjectTitle>Nomad</ProjectTitle>
            <ProjectDate>2018</ProjectDate>
          </ProjectDetails>
        </ProjectPreview>
        <ProjectPreview>
          {/* <Thumbnail to="/work/color-inspiration"> */}
          <AuthLink href="/work/color-inspiration">
            <Img fluid={colorInspThumbnail.childImageSharp.fluid} />
          </AuthLink>
          {/* </Thumbnail> */}
          <ProjectDetails>
            <ProjectTitle>Color Inspiration</ProjectTitle>
            <ProjectDate>2019</ProjectDate>
          </ProjectDetails>
        </ProjectPreview>
        <ProjectPreview>
          <Thumbnail to="/work/dreamnest">
            {/* This happens asynchronsouly , so the full height of the image might nob eknown at the itm the container widh is set. solution set a global load flag to check in the smooth scroll or try a the image plugni */}
            <Img fluid={dreamnestThumbnail.childImageSharp.fluid} />
          </Thumbnail>
          <ProjectDetails>
            <ProjectTitle>Dreamnest</ProjectTitle>
            <ProjectDate>2018</ProjectDate>
          </ProjectDetails>
        </ProjectPreview>
      </WorkContentGrid>
    </Container>
  )
}

export default WorkContent
